import styled from "styled-components";

export default styled.div`
    display: grid;
    grid-template-columns: repeat(
        auto-fit,
        minmax(
            ${props => (props.columnWidth ? props.columnWidth : "300px")},
            1fr
        )
    );
    column-gap: ${props => (props.gap ? props.gap : "4rem")};
`;
