import TestimonialImage from "../../../../src/components/Visual/TestimonialImage";
import CustomerTestimonial from "../../../../src/components/Visual/CustomerTestimonial";
import Question from "../../../../src/svg/question.svg";
import Pitching from "../../../../src/svg/pitching.svg";
import Analytics from "../../../../src/svg/analytics.svg";
import * as React from 'react';
export default {
  TestimonialImage,
  CustomerTestimonial,
  Question,
  Pitching,
  Analytics,
  React
};