import React from "react";
import styled from "styled-components";

const Title = ({ title, subtitle, className }) => {
    return (
        <div className={className}>
            <h2>
                <span className="title">{title}</span>
                <span>{subtitle}</span>
            </h2>
        </div>
    );
};

export default styled(Title)`
    /* text-transform: uppercase; */
    font-size: 2rem;
    margin-bottom: 1rem;
    /* margin-top: 1rem; */
    h2 {
        line-height: 1.2;
        span {
            font-size: 3rem;
        }
        text-align: center;
        /* letter-spacing: 7px; */
        color: var(--primaryColor);
        @media (min-width: 576px) {
            text-align: left;
            line-height: 1.4;
            span {
                font-size: 2.8rem;
            }
        }
    }
    .title {
        color: var(--mainBlack);
        @media (min-width: 576px) {
            span {
                font-size: 2.8rem;
            }
        }
        span {
            display: block;
            font-size: 2rem;
        }
        @media (min-width: 576px) {
            span {
                display: inline-block;
                margin: 0 0.35rem;
            }
        }
    }
`;
