import styled from "styled-components";

export default styled.p`
    color: white;
    font-weight: 400;
    font-size: 1.5rem;
    text-align: left;
    width: 80%;
    @media screen and (max-width: 768px) {
        font-size: 1rem;
    }
`;
