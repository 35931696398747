import styled from "styled-components";
import React from "react";
const StyledDiv = styled.div`
    float: right;
    width: ${props => (props.width ? props.width : "50%")};
    clear: both;
    margin-left: 1rem;
    @media (max-width: 1000px) {
        display: block;
        width: 100%;
        clear: both;
        margin-bottom: 4rem;
        & img {
            width: 70%;
            margin-left: auto;
            margin-right: auto;
            text-align: center;
            display: block;
        }
    }
`;
export default ({ children, width }) => {
    return <StyledDiv width={width}>{children}</StyledDiv>;
};
