import React from "react";
import styled from "styled-components";
import Wrapper from "./Visual/Wrapper";

const Content = styled.header`
    position: static;
    top: 0;
    left: 0;
    min-height: ${props => (props.height ? props.height : 100)}vh;
    align-items: center;
    min-width: 100%;
    pointer-events: all;
    background-color: var(--primaryColor);
`;

const ProductName = styled.h1`
    color: var(--mainWhite);
    text-align: left;
    font-size: 3rem;
    vertical-align: center;
    @media (max-width: 860px) {
        font-size: 2.7rem;
        margin-top: 1rem;
    }
`;

const StyledImage = styled.img`
    @media (max-width: 860px) {
        max-width: 50%;
        max-height: 50%;
    }
    max-height: 65%;
    max-width: 65%;
`;

const StyledWrapper = styled(Wrapper)`
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
    height: ${props => (props.height ? props.height + "vh" : "67vh")};
    gap: 2rem;
    margin-top: 3rem;
    @media (max-width: 1060px) {
        display: block;
        margin-top: 7rem;
        vertical-align: center;
    }
`;

export default ({ name, height, img, alt }) => {
    return (
        <Content height={height}>
            <StyledWrapper height={height}>
                <StyledImage src={img} alt={alt} />
                <ProductName>{name}</ProductName>
            </StyledWrapper>
        </Content>
    );
};
