import Title from "../../../../src/components/Title.js";
import ContactForm from "../../../../src/components/ContactForm";
import CustomerTestimonial from "../../../../src/components/Visual/CustomerTestimonial";
import PriceBox from "../../../../src/components/Visual/PriceBox";
import * as React from 'react';
export default {
  Title,
  ContactForm,
  CustomerTestimonial,
  PriceBox,
  React
};