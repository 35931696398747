import React from "react";
import SimpleHero from "./Visual/SimpleHero";
import Cart from "../svg/cart.svg";

export default () => {
    return (
        <SimpleHero
            image={
                <Cart
                    style={{
                        position: "absolute",
                        bottom: 0,
                        height: "67%",
                        width: "90%",
                        left: 0,
                        right: 0,
                        marginLeft: "auto",
                        marginRight: "auto",
                    }}
                />
            }
            height="67vh"
            centered
        />
    );
};
