import React from "react";
import CustomerTestimonial from "../components/Visual/CustomerTestimonial";
import SimpleButton from "../components/Visual/SimpleButton";
import { Link } from "gatsby";
const FrontPageText = {
    en: {
        content: {
            headerDescription:
                "Dynapro is a Microsoft partner specializing in selling, supporting and creating solutions for Microsoft Dynamics 365 Business Central.",
            headerTitle:
                "Dynapro - Microsoft Partner and Business Central Vendor",
            mainButtonText: "Contact us",
            secondaryButtonText: "Learn more",
            slogan: "Take your business processes to the next level",
            subText: (
                <span>
                    We help you get the most out of Microsoft Dynamics 365
                    Business Central
                </span>
            ),
        },

        featureContent: {
            certifiedAccounting:
                "Business Central is a certified accounting system in Norway and many other countries and supports the following features out of the box:",
            interested:
                "Interested in finding out how Business Central could benefit your business?",
            aboutBC: (
                <>
                    <p>
                        Microsoft Dynamics 365 Business Central (Business
                        Central for short) is an ERP system (enterprise resource
                        planning) for small to medium sized businesses, that
                        simplifies everyday tasks by organizing and automating
                        business processes.
                    </p>
                    <p>
                        It allows you to manage finance and accounting,
                        manufacturing, sales, procurement, supply chain,
                        reporting analytics, project management, operations,
                        shipping, service and many other aspects of your
                        business in one place.
                    </p>
                    <br />
                </>
            ),
            norwegianLocalization: (
                <>
                    <li>Invoicing using EHF (Elektronisk handelsformat)</li>
                    <li>Exporting accounting data in the SAF-T format</li>
                    <li>Bank remittance</li>
                    <li>GDPR compliance</li>
                    <li>KID numbers on Sales Documents</li>
                </>
            ),
            processes: (
                <>
                    <p>
                        With Business Central you can manage the following
                        business processes in one place:
                    </p>
                    <li>Finance and accounting</li>
                    <li>Manufacturing</li>
                    <li>Sales</li>
                    <li>Procurement</li>
                    <li>Supply Chain</li>
                    <li>Reporting Analytics</li>
                    <li>Project Management</li>
                    <li>Operations</li>
                    <li>Shipping</li>
                    <li>Service</li>
                </>
            ),
            pullTogether: (
                <h3>An integrated ERP system that streamlines processes</h3>
            ),
            standoutFeatures:
                "Why Business Central takes the modern ERP system to new heights",
            title:
                "Everything you would expect from a modern ERP system and more",
            contactUs: "Contact us",
        },

        companyContent: {
            text: (
                <>
                    <p>
                        Dynapro is a small but dedicated company that
                        specializes in ERP solutions for small and medium-sized
                        businesses. We are a Microsoft partner and vendor of
                        Microsoft Dynamics 365 Business Central, located in
                        Asker in Norway.
                    </p>
                    <p>
                        Our primary goal is to help you run your business
                        operations as efficiently and smoothly as possible, by
                        providing you and your employees with effective ERP
                        solutions.
                    </p>
                    <p>
                        We offer support and consultation to our customers for
                        challenges large and small. With over 25 years of
                        experience working with ERP systems, we will help you
                        find the right fit for your business. Our consultants
                        specialize in working with Microsoft Dynamics 365
                        Business Central and Dynamics NAV.
                    </p>
                    <p>
                        We also create customized solutions. With
                        customizations, your user experience and the
                        functionality of Dynamics 365 Business Central are
                        enhanced. We specialize in creating solutions in the
                        areas of mechanical and chemical manufacturing and
                        trade. We have implemented and designed many solutions,
                        both in Norway and abroad.
                    </p>
                    <p>
                        If you decide to work with us, you will join lots of
                        happy customers, some of which have been with us for
                        over 20 years.
                    </p>
                    <SimpleButton transparentOnHover={false} centered={true}>
                        <Link to="about">More about us</Link>
                    </SimpleButton>
                    <br />
                    <CustomerTestimonial
                        imgUrl="/media/customerLogos/viacon.png"
                        quote="“Dynapro has been ViaCon AS’s Dynamics NAV partner since 2015. During our recent transition to Dynamics 365 Business Central, Dynapro has been of immeasurable value! We receive immediate responses to our questions from friendly, skilled professionals who really listen to us as a customer. The solutions proposed to us are of high quality, which make our day-to-day tasks easier. For us, it is important to be able to rely on a partner like Dynapro.”"
                        name="Svanhild Wåltorp"
                        jobTitle="CEO at ViaCon AS"
                        centered
                    />
                </>
            ),
            title: "Who are we?",
            moreAboutDynapro: "More about Us",
        },
    },
    no: {
        content: {
            headerTitle:
                "Dynapro - Microsoft partner og Business Central leverandør",
            headerDescription:
                "Dynapro er en Microsoft-partner som spesialiserer seg på salg, støtte og å skape løsninger for Microsoft Dynamics 365 Business Central.",
            slogan: "Ta forretningsprosessene dine til neste nivå",
            subText: (
                <span>
                    Vi hjelper deg å få mest mulig ut av Microsoft Dynamics 365
                    Business Central
                </span>
            ),
            mainButtonText: "Kontakt oss",
            secondaryButtonText: "Finn ut mer",
        },
        featureContent: {
            title: "Alt du forventer av et moderne ERP-system og mer",
            certifiedAccounting:
                "Business Central har millioner av brukere over hele verden, og oppfyller norsk bokføringslov. Systemet støtter alle de vanlige regnskapsfunksjoner som f.eks.:",
            norwegianLocalization: (
                <>
                    <li>
                        Fakturering ved bruk av EHF (Elektronisk handelsformat)
                    </li>
                    <li>Eksportering av regnskapsdata i SAF-T-format</li>
                    <li>Remittering (bank)</li>
                    <li>GDPR</li>
                    <li>Innlesning av betalingsfiler fra bank (OCR)</li>
                </>
            ),
            aboutBC: (
                <>
                    <p>
                        Microsoft Dynamics 365 Business Central (forkortet
                        Business Central) er et ERP-system (enterprise resource
                        planning) for små og mellomstore bedrifter, som
                        forenkler dagligdagse oppgaver ved å organisere og
                        automatisere forretningsprosesser.
                    </p>
                    <p>
                        Den lar deg administrere økonomi og regnskap,
                        produksjon, salg, innkjøp, forsyningskjede,
                        rapporteringsanalyse, prosjektledelse, frakt, service og
                        mange andre aspekter av virksomheten din på ett sted.
                    </p>
                    <br />
                </>
            ),
            standoutFeatures:
                "Hvorfor Business Central tar det moderne ERP-systemet til nye høyder",
            interested:
                "Interessert i å finne ut hvordan Business Central kan hjelpe din virksomhet?",
            contactUs: "Kontakt oss",
        },
        companyContent: {
            text: (
                <>
                    <p>
                        Dynapro er et lite, men dedikert selskap som
                        spesialiserer seg på å levere Microsoft Dynamics 365
                        Business Central til små og mellomstore bedrifter. Vi er
                        en Microsoft-partner og leverandør av Microsoft Dynamics
                        365 Business Central, lokalisert i Asker i Norge.
                    </p>
                    <p>
                        Vårt primære mål er å hjelpe deg med å gjøre
                        forretningsprosessene dine så effektive som mulig.
                    </p>
                    <p>
                        Vi gjør dette ved å tilby opplæring, assistanse og råd
                        angående Business Central, tilby standardiserte
                        utvidelser for systemet eller lage skreddersydde
                        løsninger på toppen av Business Central.
                    </p>
                    <p>
                        Vi har laget standardiserte utvidelser for{" "}
                        <Link to="products/dynapro-trade">kjøp og salg</Link>,
                        <Link to="products/dynapro-manufacturing">
                            {" "}
                            produksjon
                        </Link>
                        ,{" "}
                        <Link to="products/online-production-control">
                            produksjonskontroll
                        </Link>
                        , <Link to="products/cargonizer-connector">frakt</Link>,
                        <Link to="products/material-planner">
                            {" "}
                            varetilgjengelighet
                        </Link>
                        ,<Link to="products/service-management"> service</Link>,
                        og{" "}
                        <Link to="products/dynapro-electronic-documents">
                            bestilling/fakturering (EHF)
                        </Link>{" "}
                        som gjør livet enklere for våre kunder.
                    </p>
                    <p>
                        Vi har også laget en rekke skreddersydde tilpasninger
                        som øker effektiviteten for brukerne samt integrasjoner
                        som integrerer Business Central blant annet med systemer
                        som omhandler{" "}
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.descartes.com/home"
                        >
                            {" "}
                            fortolling
                        </a>
                        ,{" "}
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://en.wikipedia.org/wiki/Manufacturing_execution_system"
                        >
                            produksjon
                        </a>
                        ,{" "}
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://landax.no/"
                        >
                            kvalitetssikring
                        </a>
                        ,{" "}
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.digipost.no/"
                        >
                            digital post
                        </a>
                        ,{" "}
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.knowit.no/kunder/handel-og-tjenester/brilleland/brilleland/"
                        >
                            e-handel
                        </a>{" "}
                        og mye mer.
                    </p>
                    <p>
                        Hvis du bestemmer deg for å jobbe med oss, vil du bli en
                        av mange fornøyde kunder, hvorav noen har vært hos oss i
                        over 20 år.
                    </p>
                    <SimpleButton transparentOnHover={false} centered={true}>
                        <Link to="about">Mer om Dynapro</Link>
                    </SimpleButton>
                    <br />
                    <CustomerTestimonial
                        imgUrl="/media/customerLogos/viacon.png"
                        quote="«ViaCon AS har siden 2015 hatt Dynapro som sin Dynamics NAV partner, og ved overgang til Dynamics 365 Business Central har Dynapro vært gull verdt! Vi opplever umiddelbar respons fra hyggelige, dyktige fagfolk som lytter til oss som kunde. Det er høy kvalitet på løsningsforslagene, som igjen gjør arbeidshverdagen vår lettere. For oss er det trygt og viktig å ha en samarbeidspartner som Dynapro.»"
                        name="Svanhild Wåltorp"
                        jobTitle="Daglig leder hos ViaCon AS"
                        centered
                    />
                </>
            ),
            title: "Hvem er vi?",
        },
    },
};

export default FrontPageText;
