import PriceBox from "../../../../src/components/Visual/PriceBox";
import ProductHeadline from "../../../../src/components/Visual/ProductHeadline";
import ProductHeader from "../../../../src/components/ProductHeader";
import ContactForm from "../../../../src/components/ContactForm";
import EDILogo from "../../../../src/images/edi.png";
import * as React from 'react';
export default {
  PriceBox,
  ProductHeadline,
  ProductHeader,
  ContactForm,
  EDILogo,
  React
};