import styled from "styled-components";

export default styled.div`
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    width: 90%;
    max-width: ${props => (props.maxWidth ? props.maxWidth : "1140px")};

    @media (min-width: 1200px) {
        width: 80%;
    }
`;
