import React from "react";
import { FaPhoneAlt, FaAt, FaMapMarkerAlt, FaLinkedinIn } from "react-icons/fa";
import styled from "styled-components";
import { maxWidthForSingleColumn } from "../constants/variousConstants";

const Container = styled.div`
    & a {
        text-decoration: none;
        color: var(--offWhite);
    }
`;

const FaWrapper = styled.div`
    background-color: var(--offWhite);
    color: var(--primaryColor);
    padding: 30px;
    border-radius: 50%;
    text-align: center;
    line-height: 0;
    height: auto;
    width: 90px;
    height: 90px;
    margin-left: auto;
    margin-right: auto;

    & .fa {
        font-size: 30px;
        vertical-align: middle;
        text-align: center;
    }
`;

const ContactDetailText = styled.div`
    width: 200px;
    margin-top: 0.4rem;
    margin-bottom: 0.7rem;
    text-align: center;
    display: block;
    font-size: 1.2rem;
    margin-left: auto;
    margin-right: auto;
`;

const DetailWrapper = styled.article`
    margin: 0 2rem;
    vertical-align: middle;
    display: inline;
    @media (max-width: ${maxWidthForSingleColumn}px) {
        font-size: 1.3rem;
        display: block;
        overflow: hidden;
        height: auto;
        margin: 2rem auto;
    }
`;

const ContactDetails = () => {
    return (
        <Container>
            <a aria-label="Phone number" href="tel:+47 957 34 498">
                <DetailWrapper>
                    <FaWrapper>
                        <FaPhoneAlt className="fa" />
                    </FaWrapper>
                    <ContactDetailText>+47 957 34 498</ContactDetailText>
                </DetailWrapper>
            </a>

            <a aria-label="Email" href="mailto:dynapro@dynapro.no">
                <DetailWrapper>
                    <FaWrapper>
                        <FaAt className="fa" />
                    </FaWrapper>
                    <ContactDetailText>dynapro@dynapro.no</ContactDetailText>
                </DetailWrapper>
            </a>

            <a
                href="https://www.google.com/maps/place/Dynapro+AS/@59.8333492,10.429874,17z/data=!4m14!1m7!3m6!1s0x46411562c274fd51:0x72e0d10ba61d6947!2sDynapro+AS!8m2!3d59.8333492!4d10.4324543!16s%2Fg%2F11h4g4wf34!3m5!1s0x46411562c274fd51:0x72e0d10ba61d6947!8m2!3d59.8333492!4d10.4324543!16s%2Fg%2F11h4g4wf34?entry=ttu"
                aria-label="Location"
                target="newWindow"
            >
                <DetailWrapper>
                    <FaWrapper>
                        <FaMapMarkerAlt className="fa" />
                    </FaWrapper>
                    <ContactDetailText>
                        Skysstasjonen 11 B<br />
                        1383 Asker
                    </ContactDetailText>
                </DetailWrapper>
            </a>
            <a
                href="https://no.linkedin.com/company/dynapro-as/"
                aria-label="LinkedIn"
                target="newWindow"
            >
                <DetailWrapper>
                    <FaWrapper>
                        <FaLinkedinIn className="fa" />
                    </FaWrapper>
                    <ContactDetailText>LinkedIn</ContactDetailText>
                </DetailWrapper>
            </a>
        </Container>
    );
};

export default styled(ContactDetails)``;
