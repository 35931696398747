import React from "react";
import styled from "styled-components";

const PlayerContainer = styled.div`
    position: relative;
    padding-bottom: 56.25%;
    /* padding-top: 30px; */
    height: 0;
    overflow: hidden;
    & iframe,
    & object,
    & embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
`;

const YouTubeContainer = ({ url }) => {
    return (
        <PlayerContainer>
            <iframe
                width="1140"
                height="641"
                src={url}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullscreen
            ></iframe>
        </PlayerContainer>
    );
};

export default YouTubeContainer;
