import React from "react";
import styled from "styled-components";
import Wrapper from "../Visual/Wrapper";
import ImageContainer from "../Visual/ImageContainer";
import Title from "../Title";
import Working from "../../svg/working.svg";

const CompanyDescription = ({ content }) => {
    return (
        <StyledSection>
            <Wrapper maxWidth="860px">
                <Title title={content.title} />
                <ImageContainer width="50%">
                    <Working style={{ width: "100%", height: "auto" }} />
                </ImageContainer>
                {content.text}

                <StyledPartnerContainer style={{}}></StyledPartnerContainer>
            </Wrapper>
        </StyledSection>
    );
};

export default CompanyDescription;

const StyledSection = styled.section`
    padding: 1.5rem 0;
`;

const StyledPartnerContainer = styled.div`
    margin: 0px auto;
    display: block;
    width: 50%;
    @media (max-width: 576px) {
        width: 90%;
    }
`;
