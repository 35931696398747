import PriceBox from "../../../../src/components/Visual/PriceBox";
import ProductHeader from "../../../../src/components/ProductHeader";
import ContactForm from "../../../../src/components/ContactForm";
import ProductHeadline from "../../../../src/components/Visual/ProductHeadline";
import MaterialPlannerLogo from "../../../../src/images/materialplanner.png";
import CustomerTestimonial from "../../../../src/components/Visual/CustomerTestimonial";
import * as React from 'react';
export default {
  PriceBox,
  ProductHeader,
  ContactForm,
  ProductHeadline,
  MaterialPlannerLogo,
  CustomerTestimonial,
  React
};