import React from "react";
import styled, { css } from "styled-components";

const SimpleButton = styled.div`
    margin-top: 1rem;
    ${props =>
        props.inline &&
        css`
            display: inline-block;
            margin-right: 1rem;
        `}
    ${props =>
        props.centered &&
        css`
            margin-left: auto;
            margin-right: auto;
            text-align: center;
        `}
    & a {
        text-decoration: none;
        font-size: 1.5rem;
        font-weight: 600;
        /* border: 2px solid var(--mainWhite); */
        padding: 0.9rem 1.6rem;
        display: inline-block;
        transition: var(--mainTransition);
        cursor: pointer;
        z-index: 0;
        pointer-events: all;
        background: ${props =>
            props.backgroundColor
                ? props.backgroundColor
                : "var(--primaryColor)"};
        color: ${props => (props.color ? props.color : "var(--mainWhite)")};
        border: 2px solid
            ${props =>
                props.borderColor ? props.borderColor : "var(--primaryColor)"};

        ${props =>
            props.transparentOnHover &&
            css`
                &:hover {
                    background: transparent;
                }
            `}
        @media (max-width: 600px) {
            font-size: 1.2rem;
            padding: 0.7rem 1.2rem;
            display: block;
        }
    }
`;

export default ({
    children,
    transparentOnHover,
    centered,
    backgroundColor,
    borderColor,
    color,
    inline,
}) => {
    return (
        <SimpleButton
            centered={centered}
            transparentOnHover={transparentOnHover}
            backgroundColor={backgroundColor}
            color={color}
            inline={inline}
            borderColor={borderColor}
        >
            {children}
        </SimpleButton>
    );
};
