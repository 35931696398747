import React from "react";
import styled from "styled-components";

const PriceBox = ({ title, price, quantification, description }) => {
    return (
        <StyledContainer>
            <StyledH3>{title}</StyledH3>
            <Price>{price}</Price>
            <Quantification>{quantification}</Quantification>
            <Description>{description}</Description>
        </StyledContainer>
    );
};

export default PriceBox;

const StyledContainer = styled.div`
    border: 1px solid #ccc;
    padding: 1rem;
    margin-bottom: 1rem;
`;

const StyledH3 = styled.h3`
    font-size: 1.3rem;
`;

const Price = styled.span`
    font-weight: bold;
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: 3rem;
    display: block;
`;

const Description = styled.p`
    margin-top: 1rem;
`;

const Quantification = styled.span`
    color: #aaa;
    font-weight: bold;
`;
