import React from "react";
import styled from "styled-components";
import Title from "./Title";
import ContactDetails from "./ContactDetails";
import Wrapper from "./Visual/Wrapper";
import { maxWidthForSingleColumn } from "../constants/variousConstants";
import contactText from "../constants/contactText";

const ContactForm = ({ langCode, formName, displayName, dark }) => {
    const content = contactText[langCode];

    const [showPhone, setshowPhone] = React.useState(0);
    React.useEffect(() => {
        setshowPhone(true);
        setTimeout(function() {
            setshowPhone(false);
        }, 500);
    }, []);
    return (
        <StyledContactForm dark={dark}>
            <Wrapper>
                <StyledTitle
                    title={displayName ? displayName : content.title}
                />
                <ContactWrapper>
                    <ContactDetails />
                    <form
                        name={formName ? formName : "contact"}
                        method="post"
                        action="/thankyou"
                        data-netlify="true"
                        data-netlify-honeypot="phone"
                    >
                        <label htmlFor="name">{content.nameLabel}</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            aria-label="name"
                            placeholder={content.namePlaceholder}
                            required
                        />
                        <label htmlFor="email">{content.emailLabel}</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            aria-label="email"
                            placeholder={content.emailPlaceholder}
                            required
                        />
                        {showPhone && (
                            <>
                                <label htmlFor="phone">{content.phone}:</label>
                                <input
                                    type="text"
                                    id="phone"
                                    name="phone"
                                    autoComplete="off"
                                    tabIndex="-1"
                                    placeholder={content.phoneplaceHolder}
                                    required
                                />
                            </>
                        )}
                        <label htmlFor="message">{content.messageLabel}:</label>
                        <textarea
                            id="message"
                            name="message"
                            aria-label="Message:"
                            placeholder={content.messagePlaceholder}
                        ></textarea>
                        <input type="hidden" name="form-name" value="contact" />
                        <input
                            type="submit"
                            value={content.submit}
                            className="btn-primary"
                        />
                    </form>
                </ContactWrapper>
            </Wrapper>
        </StyledContactForm>
    );
};

export default ContactForm;

const StyledContactForm = styled.section`
    color: var(--offWhite);
    background-color: ${props =>
        props.dark ? "var(--secondaryColor)" : "var(--primaryColor)"};
    padding-top: 3rem;
    font-weight: bold;
    margin: 0;

    & h1 {
        text-align: center;
    }
    & input,
    & label,
    & textarea {
        display: flexbox;
        font-size: 1.05rem;
        border: 0;
        font-family: "Roboto", sans-serif;
        width: 100%;
    }
    & textarea,
    & input {
        font-weight: bold;
        padding: 5px;
        outline: 0;
        background-color: var(--offWhite);
        height: 40px;
    }
    & textarea {
        resize: none;
        height: 30vh;
    }
    & label {
        margin-top: 20px;
        margin-bottom: 10px;
        font-size: 1.3rem;
    }
    & input[type="submit"] {
        background-color: ${props =>
            props.dark ? "var(--primaryColor)" : "var(--secondaryColor)"};
        color: var(--offWhite);
        font-weight: bold;
        text-align: center;
        cursor: pointer;
        display: block;
        margin: 20px auto;
        height: 50px;
        font-size: 1.3rem;
        text-transform: none;
        letter-spacing: 0;
    }
    & form {
        width: 60%;
        @media (max-width: ${maxWidthForSingleColumn}px) {
            width: 100%;
        }
    }
`;

const ContactWrapper = styled.div`
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    justify-content: space-between;
    vertical-align: top;
    @media (max-width: ${maxWidthForSingleColumn}px) {
        display: inline;
    }
`;

const StyledTitle = styled(Title)`
    & .title {
        color: var(--offWhite);
        margin-bottom: 0;
    }
`;
