import PriceBox from "../../../../src/components/Visual/PriceBox";
import ProductHeader from "../../../../src/components/ProductHeader";
import ContactForm from "../../../../src/components/ContactForm";
import YouTubeContainer from "../../../../src/components/Visual/YouTubeContainer";
import SimpleButton from "../../../../src/components/Visual/SimpleButton";
import ImageAndText from "../../../../src/components/Visual/ImageAndText";
import ProductHeadline from "../../../../src/components/Visual/ProductHeadline";
import TaskletLogo from "../../../../src/images/tasklet.png";
import * as React from 'react';
export default {
  PriceBox,
  ProductHeader,
  ContactForm,
  YouTubeContainer,
  SimpleButton,
  ImageAndText,
  ProductHeadline,
  TaskletLogo,
  React
};