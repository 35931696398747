import React from "react";
import SimpleHero from "./Visual/SimpleHero";
import Message from "../svg/message.svg";

export default ({ children }) => {
    return (
        <SimpleHero
            image={
                <Message
                    style={{
                        position: "absolute",
                        bottom: 0,
                        height: "50%",
                        width: "90%",
                        left: 0,
                        right: 0,
                        marginLeft: "auto",
                        marginRight: "auto",
                    }}
                />
            }
        >
            {children}
        </SimpleHero>
    );
};
