import React from "react";
import styled, { css } from "styled-components";

const StyledBanner = styled.div`
    text-align: center;
    text-align: left;
    letter-spacing: var(--mainSpacing);
    color: var(--mainWhite);
    margin-bottom: 2rem;

    & h1 {
        font-size: 1.8rem;
        margin: 0 auto;
        /* padding: 0 1rem; */
        letter-spacing: 0.1rem;
        width: 90%;
        font-weight: 600;
        display: inline-block;
        text-align: left;
        ${props =>
            props.centered &&
            css`
                display: block;
                text-align: center;
            `}
    }
    @media screen and (min-width: 768px) {
        & h1 {
            font-size: 3.2rem;
            width: 95%;
            max-width: 1170px;
        }
    }
`;

const Banner = ({ title, centered }) => {
    return (
        <StyledBanner centered={centered}>
            <h1>{title}</h1>
        </StyledBanner>
    );
};

export default Banner;
