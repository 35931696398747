import SimpleHero from "../../../../src/components/Visual/SimpleHero";
import Employees from "../../../../src/components/About/Employees";
import PriceImage from "../../../../src/components/PriceImage";
import Title from "../../../../src/components/Title.js";
import ContactForm from "../../../../src/components/ContactForm";
import CustomerTestimonial from "../../../../src/components/Visual/CustomerTestimonial";
import PriceBox from "../../../../src/components/Visual/PriceBox";
import * as React from 'react';
export default {
  SimpleHero,
  Employees,
  PriceImage,
  Title,
  ContactForm,
  CustomerTestimonial,
  PriceBox,
  React
};