import SimpleHero from "../../../../src/components/Visual/SimpleHero";
import Wrapper from "../../../../src/components/Visual/Wrapper";
import Employees from "../../../../src/components/About/Employees";
import AboutImage from "../../../../src/components/About/AboutImage";
import Title from "../../../../src/components/Title.js";
import ContactForm from "../../../../src/components/ContactForm";
import CustomerTestimonial from "../../../../src/components/Visual/CustomerTestimonial";
import * as React from 'react';
export default {
  SimpleHero,
  Wrapper,
  Employees,
  AboutImage,
  Title,
  ContactForm,
  CustomerTestimonial,
  React
};