import React from "react";
import styled from "styled-components";

const ImageWrapper = styled.div`
    position: absolute;
    min-width: 100%;
    height: ${props => (props.height ? props.height : "100vh")};
    z-index: -100;
    overflow: hidden;
    pointer-events: none;
    background: var(--primaryColor);
`;

const Content = styled.header`
    padding-top: 125px;
    min-height: ${props => (props.height ? props.height : "100vh")};
    display: flex;
    justify-content: top;
    flex-direction: column;
    align-items: center;
    min-width: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0));
    pointer-events: all;
    @media screen and (max-width: 768px) {
        padding-top: 90px;
    }
`;

const FrontPageHero = ({ children, image, height, centered }) => {
    return (
        <>
            <ImageWrapper height={height}>{image}</ImageWrapper>
            <Content height={height}> {children} </Content>
        </>
    );
};

export default FrontPageHero;
