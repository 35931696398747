import React from "react";
import SimpleHero from "./SimpleHero";
import Team from "../../svg/support.svg";

export default () => {
    return (
        <SimpleHero
            image={
                <Team
                    style={{
                        position: "absolute",
                        bottom: 0,
                        height: "67%",
                        width: "90%",
                        left: 0,
                        right: 0,
                        marginLeft: "auto",
                        marginRight: "auto",
                    }}
                />
            }
            height="67vh"
            centered="true"
        />
    );
};
