import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Wrapper from "./Wrapper";
import ProductItem from "./ProductItem";
import Grid from "./Grid";

const titles = {
    en: "Dynapro Apps for Business Central",
    no: "Dynapro apper for Business Central",
};

export default ({ lang }) => {
    const data = useStaticQuery(graphql`
        query {
            allMdx(filter: { slug: { regex: "(|[a-z][a-z])/products//" } }) {
                edges {
                    node {
                        excerpt(pruneLength: 250)
                        id
                        fields {
                            collection
                            id
                            slug
                            title
                        }
                        frontmatter {
                            creator
                            title
                            metaTitle
                            metaDescription
                            disabled
                            productName
                            thumbnail {
                                childImageSharp {
                                    gatsbyImageData(formats: [AUTO, WEBP, AVIF])
                                }
                            }
                        }
                    }
                }
            }
        }
    `);
    const { edges: posts } = data.allMdx;
    const filteredPosts = posts.filter(
        post =>
            post.node.frontmatter.title.length > 0 &&
            post.node.fields.collection === lang &&
            post.node.frontmatter.creator === `dynapro` &&
            !post.node.frontmatter.disabled
    );
    return (
        <>
            <Wrapper style={{ marginBottom: "2rem" }}>
                <h2>{titles[lang]}</h2>
                <Grid columnWidth="250px">
                    {filteredPosts.map(({ node: post }) => {
                        return <ProductItem key={post.id} post={post} />;
                    })}
                </Grid>
            </Wrapper>
        </>
    );
};
