import React from "react";
import styled from "styled-components";
import Grid from "./Grid";
import {
    FaChartBar,
    FaMobile,
    FaLaptopCode,
    FaArrowUp,
    FaFileExcel,
    FaCloud,
    FaUserGraduate,
    FaDesktop,
} from "react-icons/fa";
import SimpleButton from "./SimpleButton";
import { Link } from "gatsby";
const iconSize = 54;

const StyledSection = styled.section`
    background: var(--offWhite);
    padding: 1rem 0 1.5rem 0;
`;

const StyledArticle = styled.article`
    margin: 0 0 1rem 0;
    text-align: left;

    & span {
        display: inline-flex;
        color: var(--offWhite);
    }

    &.btn-primary {
        font-size: 0.5rem;
    }
    & h2 {
        margin: 0;
    }

    & h4 {
        font-size: 1.2rem;
        font-weight: bold;
    }
    & a {
        color: var(--mainGrey);
    }
    .center {
    }
`;

const content = {
    en: {
        whyBC: "Why choose Business Central?",
        moreAboutBC: "More about Business Central",
        office: {
            title: "The Office 365 ecosystem",
            text:
                "Minimize manual data entry and switching between applications by completing Business Central tasks within the different Office 365 applications.",
        },
        available: {
            title: "Access anywhere",
            text:
                "Use Business Central in the browser on your Windows PC or Mac, through the app on your phone or tablet.",
        },
        customizable: {
            title: "Very Customizable",
            text:
                "Business Central can be customized with extensions that increase the efficiency for users or integrate it with other systems. You can subscribe to standardized extensions in Microsoft AppSource or have us create an extension tailored to your company.",
        },
        powerbi: {
            title: "Visual reporting with Power BI",
            text:
                "Access your Business Central data within Power BI - a compelling app that visualizes your data in a way that makes it meaningful to you.",
        },
        upgrade: {
            title: "Upgrade effortlessly",
            text:
                "Get new features faster with Business Central's extension based architecture which allows for easy upgrades.",
        },
        cloud: {
            title: "On premises or cloud options",
            text:
                "Choose between hosting Business Central in the cloud or on your own hardware.",
        },
        ui: {
            title: "A Modern User Interface",
            text:
                "Enjoy a modern and consistent user interface that empowers you to get tasks done with as little effort as possible.",
        },
        learn: {
            title: "Extensive learning options",
            text:
                "Discover the teaching tips integrated into Business Central and take advantage of Microsoft's extensive training material.",
        },
    },
    no: {
        whyBC: "Hvorfor velge Business Central?",
        moreAboutBC: "Mer om Business Central",
        office: {
            title: "Office 365 økosystem",
            text:
                "Minimer manuell datainntasting og veksling mellom applikasjoner ved å fullføre Business Central-oppgaver i de forskjellige Office 365 applikasjonene.",
        },
        available: {
            title: "Tilgjengelig overalt",
            text:
                "Bruk Business Central i nettleseren på din PC eller Mac, eller gjennom appen på telefonen eller nettbrettet.",
        },
        customizable: {
            title: "Svært tilpassbart",
            text:
                "Business Central lar seg tilpasses med utvidelser som øker effektiviteten for brukerene eller integrerer med andre systemer. Du kan abonnere på standardiserte utvidelser i Microsoft AppSource eller få oss til å lage en utvidelse som er skreddersydd til din bedrift.",
        },
        powerbi: {
            title: "Visuell rapportering med Power BI",
            text:
                "Få tilgang til Business Central-dataene dine i Power BI - en overbevisende app som visualiserer dataene dine på en måte som gjør dem meningsfulle for deg.",
        },
        upgrade: {
            title: "Enkel oppgradering",
            text:
                "Få ny funksjonalitet raskt med den nye arkitekturen i Business Central som gjør det enkelt å oppgradere systemet.",
        },
        cloud: {
            title: "I skyen eller på egne servere",
            text:
                "Velg mellom å kjøre Business Central i skyen eller på dine egne servere.",
        },
        ui: {
            title: "Moderne brukergrensesnitt",
            text:
                "Dra nytte av et moderne og konsistent brukergrensesnitt som gir deg mulighet til å få oppgavene gjort med så liten innsats som mulig.",
        },
        learn: {
            title: "Omfattende muligheter for å lære",
            text:
                "Få hjelp av undervisningstipsene som er integrert i Business Central og dra nytte av Microsofts omfattende opplæringsmateriell.",
        },
    },
};

const Features = ({ langCode }) => {
    const localizedContent = content[langCode];
    return (
        <StyledSection>
            <div
                style={{
                    width: "var(--wrapperMaxWidth)",
                    maxWidth: "90%",
                    margin: "0 auto 3em auto",
                }}
            >
                <h2>{localizedContent.whyBC}</h2>
            </div>
            <Grid
                style={{
                    width: "90%",
                    margin: "0 auto",
                    maxWidth: "var(--wrapperMaxWidth)",
                }}
            >
                <StyledArticle>
                    <span>
                        {
                            <FaFileExcel
                                size={iconSize}
                                color="var(--primaryColor)"
                            />
                        }
                    </span>
                    <h4>{localizedContent.office.title}</h4>
                    <p>{localizedContent.office.text}</p>
                </StyledArticle>
                <StyledArticle>
                    <span>
                        {
                            <FaMobile
                                size={iconSize}
                                color="var(--primaryColor)"
                            />
                        }
                    </span>
                    <h4>{localizedContent.available.title}</h4>
                    <p>{localizedContent.available.text}</p>
                </StyledArticle>
                <StyledArticle>
                    <span>
                        {
                            <FaLaptopCode
                                size={iconSize}
                                color="var(--primaryColor)"
                            />
                        }
                    </span>
                    <h4>{localizedContent.customizable.title}</h4>
                    <p>{localizedContent.customizable.text}</p>
                </StyledArticle>
                <StyledArticle>
                    <span>
                        {
                            <FaChartBar
                                size={iconSize}
                                color="var(--primaryColor)"
                            />
                        }
                    </span>
                    <h4>{localizedContent.powerbi.title}</h4>
                    <p>{localizedContent.powerbi.text}</p>
                </StyledArticle>
                <StyledArticle>
                    <span>
                        {
                            <FaArrowUp
                                size={iconSize}
                                color="var(--primaryColor)"
                            />
                        }
                    </span>
                    <h4>{localizedContent.upgrade.title}</h4>
                    <p>{localizedContent.upgrade.text}</p>
                </StyledArticle>
                <StyledArticle>
                    <span>
                        {
                            <FaCloud
                                size={iconSize}
                                color="var(--primaryColor)"
                            />
                        }
                    </span>
                    <h4>{localizedContent.cloud.title}</h4>
                    <p>{localizedContent.cloud.text}</p>
                </StyledArticle>
                <StyledArticle>
                    <span>
                        {
                            <FaDesktop
                                size={iconSize}
                                color="var(--primaryColor)"
                            />
                        }
                    </span>
                    <h4>{localizedContent.ui.title}</h4>
                    <p>{localizedContent.ui.text}</p>
                </StyledArticle>
                <StyledArticle>
                    <span>
                        {
                            <FaUserGraduate
                                size={iconSize}
                                color="var(--primaryColor)"
                            />
                        }
                    </span>
                    <h4>{localizedContent.learn.title}</h4>
                    <p>{localizedContent.learn.text}</p>
                </StyledArticle>
            </Grid>
            <SimpleButton centered>
                <Link to="products/business-central">
                    {localizedContent.moreAboutBC}
                </Link>
            </SimpleButton>
        </StyledSection>
    );
};

export default Features;
