import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Wrapper from "./Wrapper";
import ProductItem from "./ProductItem";
import Grid from "./Grid";

export default ({ title, lang, firstParty, thirdParty }) => {
    const data = useStaticQuery(graphql`
        query {
            allMdx(filter: { slug: { regex: "(|[a-z][a-z])/products//" } }) {
                edges {
                    node {
                        excerpt(pruneLength: 250)
                        id
                        fields {
                            collection
                            id
                            slug
                            title
                        }
                        frontmatter {
                            creator
                            title
                            metaTitle
                            metaDescription
                            disabled
                            productName
                            thumbnail {
                                childImageSharp {
                                    gatsbyImageData(formats: [AUTO, WEBP, AVIF])
                                }
                            }
                        }
                    }
                }
            }
        }
    `);
    const { edges: posts } = data.allMdx;
    const filteredPosts = posts.filter(
        post =>
            post.node.frontmatter.title.length > 0 &&
            post.node.fields.collection === lang &&
            !post.node.frontmatter.disabled
    );
    return (
        <>
            <Wrapper style={{ marginTop: "100px", marginBottom: "2rem" }}>
                <h1>{title}</h1>
                <h2>{firstParty}</h2>
                <Grid>
                    {filteredPosts
                        .filter(
                            post => post.node.frontmatter.creator === `dynapro`
                        )
                        .map(({ node: post }) => {
                            return <ProductItem key={post.id} post={post} />;
                        })}
                </Grid>
                <h2>{thirdParty}</h2>
                <Grid>
                    {filteredPosts
                        .filter(
                            post => post.node.frontmatter.creator !== `dynapro`
                        )
                        .map(({ node: post }) => {
                            return <ProductItem key={post.id} post={post} />;
                        })}
                </Grid>
            </Wrapper>
        </>
    );
};
