import React from "react";
import styled from "styled-components";

const StyledDiv = styled.div`
    width: ${props => (props.width ? props.width : "150px")};
    margin: 0px auto;
`;

const StyledImage = styled.img`
    width: 100%;
`;
const StyledText = styled.p`
    text-align: center;
    font-weight: bold;
`;

export default ({ imgUrl, text, width }) => {
    return (
        <StyledDiv width={width}>
            <StyledImage src={imgUrl} alt={text} />
            <StyledText>{text}</StyledText>
        </StyledDiv>
    );
};
