import PriceBox from "../../../../src/components/Visual/PriceBox";
import ProductHeader from "../../../../src/components/ProductHeader";
import ContactForm from "../../../../src/components/ContactForm";
import SimpleButton from "../../../../src/components/Visual/SimpleButton";
import ProductHeadline from "../../../../src/components/Visual/ProductHeadline";
import BCLogo from "../../../../src/images/bclight.png";
import Office from "../../../../src/images/office.png";
import { StaticImage } from "gatsby-plugin-image";
import ERP from "../../../../src/images/erp.svg";
import * as React from 'react';
export default {
  PriceBox,
  ProductHeader,
  ContactForm,
  SimpleButton,
  ProductHeadline,
  BCLogo,
  Office,
  StaticImage,
  ERP,
  React
};