import React from "react";
import styled from "styled-components";

const BG = styled.div`
    background: var(--secondaryColor);
    /* background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0)); */
    width: 100%;
    margin: 0;
`;
const Container = styled.div`
    display: flex;
    min-height: calc(100vh - 180px);
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    @media screen and (max-width: 768px) {
        padding-top: 90px;
    }
`;

const ImageWrapper = styled.div`
    display: block;
    /* height: ${props => (props.height ? props.height : "100vh")}; */
     /* min-width: 100%;
    z-index: -100;
    overflow: hidden;
    pointer-events: none; */
    /* right: 0; */
    flex-basis: 405px;
    padding-top: 100px;
	@media screen and (max-width: 768px) {
        padding-top: 40px;
		flex-basis: 100%;
    }
`;

const Content = styled.header`
    padding-top: 100px;
    /* min-height: ${props => (props.height ? props.height : "100vh")}; */
    text-align: left;
    pointer-events: all;
    flex-basis: 730px;
    @media screen and (max-width: 768px) {
        padding-top: 40px;
		flex-basis: 100%;
    }
`;

const SimpleHero = ({ children, image, height }) => {
    return (
        <BG>
            <div style={{ width: "90%", maxWidth: "1140px", margin: "0 auto" }}>
                <Container height={height}>
                    <Content height={height}>{children}</Content>
                    <ImageWrapper>{image}</ImageWrapper>
                </Container>
            </div>
        </BG>
    );
};

export default SimpleHero;
