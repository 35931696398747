export default {
    no: {
        address: "Lensmannslia 4<br/>1386 Asker",
        emailLabel: "E-post",
        emailPlaceholder: "Din e-post adresse..",
        messageLabel: "Beskjed",
        messagePlaceholder: "Skriv din beskjed her...",
        nameLabel: "Navn",
        namePlaceholder: "Navnet ditt...",
        phoneplaceHolder: "Ikke fyll ut dette, det er bare for spambeskyttelse",
        submit: "Send",
        phone: "Telefon",
        title: "Kontakt oss",
    },
    en: {
        phone: "Phone",
        address: "Lensmannslia 4<br/>1386 Asker<br/>Norway",
        emailLabel: "Email",
        emailPlaceholder: "Your email address..",
        messageLabel: "Message",
        messagePlaceholder: "Your message..",
        nameLabel: "Name",
        namePlaceholder: "Your name..",
        phoneplaceHolder: "Don't fill this out it is only for spam protection",
        submit: "Send",
        title: "Contact Us",
    },
};
