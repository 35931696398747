import React from "react";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

const Container = styled.div`
    min-width: 300px;
    padding: 2rem;
`;

const ImageContainer = styled.div`
    margin-bottom: 1rem;

    img {
        width: 100%;
    }
`;
const TextContent = styled.div`
    width: 100%;
    display: block;
`;

const ProductItem = ({ post }) => {
    return (
        <Link
            to={post.fields.slug}
            style={{ margin: "1rem auto", border: "1px solid #ccc" }}
        >
            <Container>
                <ImageContainer>
                    <GatsbyImage
                        image={
                            post.frontmatter.thumbnail.childImageSharp
                                .gatsbyImageData
                        }
                        alt={post.fields.title}
                    />
                </ImageContainer>
                <TextContent>
                    <h2 style={{ marginTop: "0" }}>{post.frontmatter.title}</h2>
                    <p
                        style={{
                            color: "black",
                            fontWeight: "normal",
                        }}
                    >
                        {post.frontmatter.metaDescription}
                    </p>
                </TextContent>
            </Container>
        </Link>
    );
};

export default ProductItem;
