import React from "react";
import styled, { css } from "styled-components";

const CustomerTestimonial = ({ imgUrl, quote, name, jobTitle, centered }) => {
    return (
        <StyledDiv centered={centered}>
            {imgUrl && (
                <img
                    style={{
                        display: "block",
                        maxWidth: "100%",
                        margin: "0 auto",
                    }}
                    src={imgUrl}
                    alt="The logo of the company providing the testimonial"
                ></img>
            )}
            <StyledQuote>{quote}</StyledQuote>
            <StyledName>{name}</StyledName>
            <StyledPosition>{jobTitle}</StyledPosition>
        </StyledDiv>
    );
};

const StyledQuote = styled.p`
    text-align: center;
    font-size: 1.2rem;
`;

const StyledName = styled.p`
    margin-bottom: 0rem;
    text-align: center;
    font-size: 1rem;
    font-weight: bold;
`;

const StyledPosition = styled.p`
    color: var(--mainGrey);
    font-weight: bold;
    text-align: center;
    font-size: 1rem;
`;

const StyledDiv = styled.div`
    /* border: 1px solid #ccc; */
    margin: 4rem 0;
    padding: 1.8rem 2.4rem 0.5rem 2.4rem;
    max-width: 860px;
    ${props =>
        props.centered &&
        css`
            margin-left: auto;
            margin-right: auto;
        `} background-color: #f7f7f7;
`;

export default CustomerTestimonial;
