import ProductHeader from "../../../../src/components/ProductHeader";
import ContactForm from "../../../../src/components/ContactForm";
import ProductHeadline from "../../../../src/components/Visual/ProductHeadline";
import TransportLogo from "../../../../src/images/transport.png";
import * as React from 'react';
export default {
  ProductHeader,
  ContactForm,
  ProductHeadline,
  TransportLogo,
  React
};