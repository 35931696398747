import React, { useRef } from "react";
import FrontPageHero from "../components/Visual/FrontPageHero";
import Banner from "../components/Banner";
import Features from "../components/Visual/Features";
import ContactForm from "../components/ContactForm";
import CompanyDescription from "../components/Home/CompanyDescription";
import { Link } from "gatsby";
import { Helmet } from "react-helmet";
import SimpleButton from "../components/Visual/SimpleButton";
import frontPageText from "../constants/frontPageText";
import MainImage from "../svg/system.svg";
import Subtitle from "./Visual/Subtitle";
import DynaproProducts from "./Visual/DynaproProducts";

const FrontPage = ({ langCode }) => {
    const { content, featureContent, companyContent } = frontPageText[langCode];

    const contactRef = useRef();
    const handleContactClick = event => {
        event.preventDefault();
        contactRef.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
        });
    };

    const businessCentralRef = useRef();
    const handleBusinessCentralClick = event => {
        event.preventDefault();
        businessCentralRef.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
        });
    };
    const companyRef = useRef();
    const handleCompanyClick = event => {
        event.preventDefault();
        companyRef.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
        });
    };

    return (
        <>
            <Helmet>
                <title>{content.headerTitle}</title>
                <meta name="description" content={content.headerDescription} />
            </Helmet>
            <FrontPageHero image={<MainImage />}>
                <Banner title={content.slogan} />
                <Subtitle>{content.subText}</Subtitle>
                <span>
                    <SimpleButton
                        transparentOnHover="true"
                        style={{ width: "auto", margin: 0 }}
                        inline
                    >
                        <Link to="#" onClick={handleContactClick}>
                            {content.mainButtonText}
                        </Link>
                    </SimpleButton>
                    <SimpleButton
                        transparentOnHover="true"
                        backgroundColor="var(--secondaryColor)"
                        style={{ margin: 0, width: "auto" }}
                        inline
                    >
                        <Link to="#" onClick={handleCompanyClick}>
                            {content.secondaryButtonText}
                        </Link>
                    </SimpleButton>
                </span>
            </FrontPageHero>
            <span className="scrollToTarget" ref={companyRef} />
            <CompanyDescription
                buttonEvent={handleContactClick}
                content={companyContent}
            />
            <span className="scrollToTarget" ref={businessCentralRef} />
            <Features
                content={featureContent}
                buttonEvent={handleContactClick}
                langCode={langCode}
                id="businessCentral"
            />
            <DynaproProducts lang={langCode} />
            <span className="scrollToTarget" ref={contactRef} />
            <ContactForm langCode={langCode} />
        </>
    );
};
export default FrontPage;
