import React from "react";

const ProductHeadline = ({ children }) => {
    return (
        <span
            style={{
                fontSize: "2.5rem",
                fontWeight: "bold",
                marginBottom: "1.75rem",
                display: "block",
            }}
        >
            {children}
        </span>
    );
};

export default ProductHeadline;
