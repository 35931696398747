import PriceBox from "../../../../src/components/Visual/PriceBox";
import ProductHeader from "../../../../src/components/ProductHeader";
import ContactForm from "../../../../src/components/ContactForm";
import YouTubeContainer from "../../../../src/components/Visual/YouTubeContainer";
import SimpleButton from "../../../../src/components/Visual/SimpleButton";
import ProductHeadline from "../../../../src/components/Visual/ProductHeadline";
import ContiniaLogo from "../../../../src/images/continia.png";
import * as React from 'react';
export default {
  PriceBox,
  ProductHeader,
  ContactForm,
  YouTubeContainer,
  SimpleButton,
  ProductHeadline,
  ContiniaLogo,
  React
};